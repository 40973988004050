import { reactive } from "vue";
export default {
  setup: function setup(__props) {
    var formData = reactive({
      username: ''
    });
    var formRules = {
      username: [{
        required: true,
        message: 'Please input Activity name',
        trigger: 'blur'
      }, {
        min: 3,
        max: 5,
        message: 'Length should be 3 to 5',
        trigger: 'blur'
      }]
    };
    return function (_ctx, _cache) {
      return " Home Page ";
    };
  }
};